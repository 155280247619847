import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import SocketIoInstance from '@/plugins/socketIoInstance'
import { SocketTypes } from '@/helper/socketType'
import {
  BlockMobile,
  ConnectionLost,
  SigningOutLoading,
  SplashScreen
} from '@/components'

export default {
  components: {
    BlockMobile,
    ConnectionLost,
    SigningOutLoading,
    SplashScreen
  },
  data () {
    return {
      connectionLost: false,
      isMobile: false,
      isCompleted: false,
      startGuideNavigation: false,
      signingOut: false
    }
  },
  created () {
    this.verifyAllowedWidth()
    this.checkIsCompletedLoaded()
  },
  mounted () {
    if (window.localStorage.getItem('vuex-people4manager')) {
      // this.startGuideNavigation = !JSON.parse(window.localStorage.getItem('vuex-people4manager')).Account.account.passedTutorial
    }
    window.addEventListener('resize', () => {
      this.verifyAllowedWidth()
    })
    window.addEventListener('online', () => {
      this.connectionLost = false
    })

    window.addEventListener('offline', () => {
      this.connectionLost = true
    })

    this.$eventBus.$on('TimeOutNotification', (params) => {
      this.checkTimeOutEvent(params)
    })

    this.$eventBus.$on('signOut', this.signOut)

    this.verifyIsBotDefaultAndChangeToastText()
  },
  beforeDestroy () {
    this.$eventBus.$off('TimeOutNotification')
    this.$eventBus.$off('signOut', this.signOut)

    window.removeEventListener('online', () => {
      this.connectionLost = false
    })

    window.removeEventListener('offline', () => {
      this.connectionLost = false
    })
  },
  watch: {
    $getterMinimumResolutionReached (to) {
      if (to) {
        this.addToast({
          text: this.$t('guideNavigation.minimumResolution'),
          type: 'info',
          dismissAfter: 7000
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getterIntentions',
      'getterEntities',
      'getterBots',
      'getterIsConnectingSocket',
      'getterSelectedCompany',
      '$getterMinimumResolutionReached'
    ])
  },
  methods: {
    ...mapActions([
      'socket_connect',
      'cleanStorage'
    ]),
    ...mapMutations([
      'TOGGLE_PROCESSING_INTENTION',
      'TOGGLE_PROCESSING_BOT',
      'TOGGLE_PROCESSING_EXPRESSION',
      'TOGGLE_PROCESSING_ENTITY',
      'EDIT_BOT',
      'EDIT_INTENTION',
      'EDIT_EXPRESSION',
      'EDIT_ENTITY'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    checkIsCompletedLoaded () {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.socket_connect()

          setTimeout(() => {
            this.isCompleted = true
          }, 1000)
        }
      }

      setTimeout(() => {
        this.isCompleted = document.readyState === 'complete'
      }, 1000)
    },
    checkTimeOutEvent (params) {
      if (params.type === SocketTypes.BOT) {
        const dataTimeOutBot = {
          ...params,
          idText: 'idBot',
          name: 'name',
          toggleMutation: this.TOGGLE_PROCESSING_BOT,
          editMutation: this.EDIT_BOT,
          getter: this.getterBots
        }

        this.timeOut(dataTimeOutBot)
      }

      if (params.type === SocketTypes.INTENTION) {
        const dataTimeOutIntention = {
          ...params,
          idText: 'idIntent',
          name: 'name',
          toggleMutation: this.TOGGLE_PROCESSING_INTENTION,
          editMutation: this.EDIT_INTENTION,
          getter: this.getterIntentions
        }

        this.timeOut(dataTimeOutIntention)
      }

      if (params.type === SocketTypes.EXPRESSION) {
        const dataTimeOutExpression = {
          ...params,
          idText: 'idExpression',
          name: 'exampleText',
          toggleMutation: this.TOGGLE_PROCESSING_EXPRESSION,
          editMutation: this.EDIT_EXPRESSION,
          getter: this.getterExpressions
        }

        this.timeOut(dataTimeOutExpression)
      }

      if (params.type === SocketTypes.ENTITY) {
        const dataTimeOutEntity = {
          ...params,
          idText: 'id',
          name: 'exampleText',
          toggleMutation: this.TOGGLE_PROCESSING_ENTITY,
          editMutation: this.EDIT_ENTITY,
          getter: this.getterEntities
        }

        this.timeOut(dataTimeOutEntity)
      }
    },
    timeOut (params) {
      if (params.operation !== 'delete_intent') {
        params.toggleMutation({ [params.idText]: params.idValue })
      }

      setTimeout(() => {
        const index = _.findIndex(params.getter, { [params.idText]: params.idValue })

        if (index > -1 && Object.keys(params.getter[index]).length > 0 && params.getter[index].hasOwnProperty('processing')) {
          if (!params.getter[index].isTimeOut && params.getter[index].processing && params.operation !== 'delete_intent') {
            params.editMutation({
              ...params.getter[index],
              isTimeOut: true
            })

            params.toggleMutation({ [params.idText]: params.idValue })

            if (params.eventBusToCall && params.eventBusToCall.name) {
              this.$eventBus.$emit(params.eventBusToCall.name, params.eventBusToCall.data)
            }

            this.addToast({
              text: this.$t('privatePage.processingTimeoutExceeded'),
              type: 'warning'
            })
          }
        }
      }, 60000)
    },
    verifyAllowedWidth () {
      if (window.innerWidth < 990 || window.innerHeight < 500) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    verifyIsBotDefaultAndChangeToastText () {
      const element = document.getElementsByClassName('toast')[0].childNodes[0]

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((result) => {
          if (
            result.addedNodes.length > 0
            && (this.getterSelectedCompany && this.getterSelectedCompany.isDefault)
            && (this.getterSelectedCompany.hasOwnProperty('accountCompanies') && this.getterSelectedCompany.accountCompanies && this.getterSelectedCompany.accountCompanies.length > 0 && !this.getterSelectedCompany.accountCompanies[0].isAdmin)
          ) {
            const textToast = document.getElementsByClassName('toast-message-text')

            if (textToast.length > 0) {
              for (let i = 0; i < textToast.length; i++) {
                textToast[i].textContent = this.$t('privatePage.exampleBot')
              }
            }
          }
        })
      })

      const config = { childList: true }

      observer.observe(element, config)
    },
    signOut () {
      this.signingOut = true

      this.$firebaseAuth().signOut()
        .then(() => {
          this.signingOut = false
          this.cleanStorage()
          this.$router.push({ name: 'SignIn' })
          SocketIoInstance.disconnect()
        })
        .catch(e => {
          console.error(e)
          this.signingOut = false
        })
    }
  }
}
