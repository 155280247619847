var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "private-content"
  }, [_c('signing-out-loading', {
    attrs: {
      "active": _vm.signingOut
    }
  }), _c('p-language'), _c('p-guide-navigation'), _vm.connectionLost ? _c('connection-lost') : _vm._e(), !_vm.isCompleted || _vm.getterIsConnectingSocket ? _c('splash-screen') : _vm._e(), _vm.isMobile ? _c('block-mobile') : _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }